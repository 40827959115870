import React, { useState } from 'react';
import { HashRouter, Route } from 'react-router-dom';
import styled from 'styled-components';

import LoginScreen from './LoginScreen';
import MainScreen from './MainScreen';
import ChecadasScreen from './ChecadasScreen';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #ccc;
  background-image: gradient(#ccc, #eee);
  font-family: sans-serif;
  overflow: scroll;
`;

const App = () => {
  const [ token, setToken ] = useState('');
  return (
    <HashRouter>
      <Container>
        <Route
          exact
          path="/"
          render={props => (
            <LoginScreen
              {...props}
              setToken={setToken}
            />
          )}
        />
        <Route
          exact
          path="/main"
          render={() => (
            <MainScreen token={token} />
          )}
        />
        <Route
          exact
          path="/checadas"
          render={() => (
            <ChecadasScreen token={token} />
          )}
        />
      </Container>
    </HashRouter>
  );
}

export default App;
