import React, { useState } from 'react';
import request from 'request-promise';
import styled from 'styled-components';

import SECRETS from './secrets';
import logo from './caebes-logo.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 600px;
  height: 600px;
  border-radius: 5px;
  align-self: center;
  h2 {
    margin-bottom: 30px;
  }
`;

const Input = styled.input`
  background-color: white;
  border: 1px solid #333;
  border-radius: 5px;
  height: 30px;
  margin-bottom: 20px;
  font-size: 16px;
  padding-left: 5px;
`;

const Button = styled.button`
  background-color: #90C73F;
  border: 1px solid #90C73F;
  border-radius: 5px;
  color: white;
  height: 30px;
  font-size: 16px;
  padding: 0 20px;
`;

const Img = styled.img`
  height: 120px;
  margin-bottom: 30px;
`;

const LoginScreen = ({ setToken, history }) => {
  const [ usuario, setUsuario ] = useState('');
  const [ pass, setPass ] = useState('');

  const login = () => {
    request({
      method: 'POST',
      uri: `${SECRETS.serverUrl}/login`,
      body: {
        usuario,
        pass,
      },
      json: true,
    })
      .then((resp) => {
        const { token } = resp;
        setToken(token);
        history.push('/main');
      })
      .catch(err => console.error(err));
  };

  return (
    <Container>
      <Img src={logo} alt="Logo Caebes" />
      <h2>Ingresar al sistema</h2>
      <Input
        id="usuario"
        type="text"
        value={usuario}
        placeholder="Usuario"
        onChange={(e) => setUsuario(e.target.value)}
      />
      <Input
        id="pass"
        type="password"
        value={pass}
        placeholder="Contraseña"
        onChange={(e) => setPass(e.target.value)}
      />
      <Button onClick={login}>Entrar</Button>
    </Container>
  );
};

export default LoginScreen;
