import React, { useState, useEffect } from 'react';
import request from 'request-promise';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { subDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

import SECRETS from './secrets';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 600px;
  border-radius: 5px;
  padding: 50px 0;
`;

const DateDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  input {
    border: 1px solid #333;
    border-radius: 5px;
    height: 30px;
    text-align: center;
  }
`;

const tableStyle = {
  width: '90%',
  textAlign: 'center',
};

const rowStyle = (index) => {
  if (index % 2 === 0) {
    return {
      backgroundColor: 'rgb(244,244,244)',
    };
  }
  return {};
};

const cellStyle = {
  margin: '5px 0',
  padding: '10px 0',
};

const getUnidades = (token, setUnidades) => {
  request({
    method: 'GET',
    uri: `${SECRETS.serverUrl}/unidades`,
    json: true,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then((resp) => {
      setUnidades(resp);
    })
    .catch(err => console.error(err));
};

const twoDigits = (number) => number < 10 ? `0${number}` : number;

const getChecadas = (token, setChecadas, date, lugar) => {
  const Day = date.getUTCDate();
  const Month = date.getUTCMonth() + 1;
  const Year = date.getUTCFullYear();

  request({
    method: 'GET',
    uri: `${SECRETS.serverUrl}/paradas/${Year}-${twoDigits(Month)}-${twoDigits(Day)}/${lugar}`,
    json: true,
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then((resp) => {
      setChecadas(resp.sort((a, b) => {
        const dateA = new Date(`2021-01-01T${a.hora}`);
        const dateB = new Date(`2021-01-01T${b.hora}`);
        return dateA < dateB ? -1 : 1;
      }));
    })
    .catch(err => console.error(err));
};


const ChecadasScreen = ({ token }) => {
  const [ unidades, setUnidades ] = useState([]);
  const [ checadas, setChecadas ] = useState([]);
  const [ date, setDate ] = useState(subDays(new Date(), 1));
  const [ lugar, setLugar ] = useState('all');

  useEffect(() => {
    getUnidades(token, setUnidades);
  }, [token,]);

  useEffect(() => {
    registerLocale('es', es);
  }, []);

  return (
    <Container>
      {!token && <Redirect to="/" />}
      <a href="#/main">Ir a Reporte de Unidades</a>
      <DateDiv>
        <span>Selecciona la fecha a consultar</span>
        <DatePicker
          selected={date}
          onChange={setDate}
          minDate={new Date('Jan 29, 2022')}
          maxDate={subDays(new Date(), 1)}
          placeholderText="Selecciona la fecha a consultar"
          locale="es"
        />
      </DateDiv>
      <DateDiv>
        <span>Selecciona el lugar</span>
        <select name="lugar" id="lugar" value={lugar} onChange={e => setLugar(e.target.value)}>
          <option value="all">Todos</option>
          <option value="Base">Base/Arko</option>
          <option value="Central">Central</option>
          <option value="Cuanajo">Cuanajo</option>
          <option value="Cuchilla">Cuchilla</option>
          <option value="Cuchilla-1">Cuchilla 1</option>
          <option value="Cuchilla-2">Cuchilla 2</option>
          <option value="Etucuaro">Etúcuaro</option>
          <option value="Las-Penas">Las Peñas</option>
          <option value="Martina">La Martina</option>
          <option value="San-Miguel">San Miguel</option>
          <option value="San-Pedro">San Pedro</option>
          <option value="Tirio">Tirio</option>
          <option value="Tiripetio">Tiripetio</option>
          <option value="Undameo">Undameo</option>
          <option value="Uruapilla">Uruapilla</option>
          <option value="Villa-Madero">Villa Madero</option>
          <option value="Xangari">Xangari</option>
        </select>
      </DateDiv>
      <div>
        <button onClick={() => getChecadas(token, setChecadas, date, lugar)}>
          Buscar
        </button>
      </div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th>
              Unidad
            </th>
            <th>
              Fecha
            </th>
            <th>
              Hora
            </th>
            <th>
              Lugar
            </th>
          </tr>
        </thead>
        <tbody>
          {checadas.map((ch, index) => (
            <tr style={rowStyle(index)}>
              <td style={cellStyle}>
                {unidades.find(u => u.idUnidad === ch.idUnidad).unidad}
              </td>
              <td style={cellStyle}>
                {ch.fecha}
              </td>
              <td style={cellStyle}>
                {ch.hora}
              </td>
              <td style={cellStyle}>
                {ch.lugar}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default ChecadasScreen;
